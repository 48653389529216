



import { Component, Prop, Vue, Watch  } from 'vue-property-decorator';
@Component
export default class Input extends Vue{
    beforeMount(){
        this.$data.inputValue = this.$props.value
    }
    inputValue = ''
    @Prop({type:String,required:true}) private id!: string ;
    @Prop({required:false}) private value!: string | number;
    
    @Watch('value')
    watchValue(newValue:any,oldValue:any){
         this.$data.inputValue = newValue
    }

    validate():Promise<any>{
        const key = this.$props.id
        const value = this.$props.value
                
        return new Promise((resolve,reject)=>{
            resolve({[key]:value})
        })
    }
}
