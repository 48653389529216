













































































































































































































import _ from "lodash";
 import draggable from 'vuedraggable'
import Api, { Response, Project,Calendar,Day,Supplier,Food, FoodClass } from "@/api/Api";
import TSForm from "@/components/Form.vue";
import TSInput from "@/components/Input.vue";
import TSButton from "@/components/Button.vue";
import TSUpload from "@/components/Upload.vue";
import TSField from "@/components/Field.vue";
import { MessageBox } from 'element-ui';
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
    components:{
        'ts-form':TSForm,
        'ts-input':TSInput,
        'ts-button':TSButton,
        'ts-upload':TSUpload,
        'ts-field':TSField,
        draggable
    }
})
export default class SupplierVue extends Vue {
  beforeMount() {
    this.headers['WEBADMINUSERSESSIONID'] = Api.getHeader('WEBADMINUSERSESSIONID')
    Api.supplier().then((res: Response<Array<Supplier>>) => {
      this.supplieres = res.data;
      if(res.data.length==0){
          return
      }
      try{
        this.supplier = res.data[0]
        const foodClass = _.find(res.data[0].foodClass,(o:FoodClass)=> o.name === '全部') as FoodClass
        this.allFoods = foodClass.foods
      }catch(e){

      }
    });
  }
    supplieres = [] as Array<Supplier>;
    supplier = {} as Supplier
    foods = [] as Array<Food>
    allFoods = [] as Array<Food>
    newFoodClassDialogVisible = false
    newSupplierDialogVisible = false
    newFoodDialogVisible = false
    foodForm = {picUrl:''} as Food
    foodClass = {} as FoodClass
    foodClassName = ''
    supplierName = ''
    headers = {} as any
    rules = {
        picUrl:[{required: true, message: '请上传图片', trigger: 'blur'}],
        title:[{required: true, message: '请输入菜品名称', trigger: 'blur'}],
        salePrice:[{required: true, message: '请输入售价', trigger: 'blur'}],
        originalPrice:[{required: true, message: '请输入原价', trigger: 'blur'}],
    }
    deleteFoodDialogVisible = false
    pickFoodDialogVisible = false
    sortFoodDialogVisible = false
    async _refreshFoodClass(){
        const response: Response<Array<Supplier>>  = await Api.supplier()
        this.supplieres = response.data;
        const supplier:Supplier = _.find(response.data,(o:Supplier)=>{
            return o.id === this.supplier.id
        }) as Supplier
        this.supplier = supplier
        const foodClass:FoodClass = _.find(supplier.foodClass,(o:FoodClass)=>{
            return o.name === '全部'
        }) as FoodClass
        _.sortBy(foodClass.foods, o=> {
            const d = new Date(o.createTime)
            return d.getTime()
        })
        this.allFoods = foodClass.foods
    }
    _menuItemClickEventHandler(item:Supplier){
        this.supplier = item
        const foodClass:FoodClass = _.find(item.foodClass,(o:FoodClass)=>{
            return o.name === '全部'
        }) as FoodClass
        _.sortBy(foodClass.foods, o=> {
            const d = new Date(o.createTime)
            return d.getTime()
        })
        this.allFoods = foodClass.foods
    }
    _newFoodClassBtnClickEventHandler(){
        this.newFoodClassDialogVisible = true
    }
    async _foodClassBtnClickEventHandler(item:FoodClass){
        this.foodClass = item
        const response: Response<Array<Supplier>>  = await Api.supplier()
        if(response.code === 200){
            const supplier:Supplier = _.find(response.data,(o:Supplier)=>{
                return o.id === this.supplier.id
            }) as Supplier
            const foodClass:FoodClass = _.find(supplier.foodClass,(o:FoodClass)=>{
                return o.name === '全部'
            }) as FoodClass
            this.foods = foodClass.foods
        }
        this.pickFoodDialogVisible = true
    }
    _newFoodDialogSuccessHandler(){

    }
    _newFoodDialogFailHandler(){

    }
    async _newFoodBtnClickEventHandler(){
        const response: Response<Array<Supplier>>  = await Api.supplier()
        if(response.code === 200){
            const supplier:Supplier = _.find(response.data,(o:Supplier)=>{
                return o.id === this.supplier.id
            }) as Supplier
            const foodClass:FoodClass = _.find(supplier.foodClass,(o:FoodClass)=>{
                return o.name === '全部'
            }) as FoodClass
            this.allFoods = foodClass.foods
        }
        this.newFoodDialogVisible = true
    }
    pictureUploadSuccess(res:Response<{url:string}>){
        if(res.code===200){
            this.foodForm.picUrl = res.data.url
        }
    }
    _foodFormConfirmBtnClickEventHandler(){
        this.foodForm.supplierId = this.supplier.id
       const foodForm:any = this.$refs['foodForm']
       const newRequest = async ()=>{
         const res:Response<Food> = await Api.supplier_food(this.foodForm)
         if(res.code===200){
             Api.success('操作成功')
             this.foodForm = {} as Food

             const response:Response<Array<Supplier>> = await Api.supplier()
            this.supplieres = response.data

            this.supplier = _.find(this.supplieres,(o:Supplier)=>{
                return o.id === this.supplier.id
            }) as Supplier
         }
       }
       const updateRequest = async ()=>{
         const res:Response<Food> = await Api.supplier_food_id(this.foodForm)
         if(res.code===200){
             Api.success('操作成功')
             this.foodForm = {} as Food
         }
       }
       
       foodForm.validate((valid:boolean)=>{
           if(!valid){
               return
           }
           this.foodForm.id!=null ? updateRequest()  : newRequest()
       })
    }
    _foodEditBtnClickEventHandler(item:Food){
        this.foodForm = Object.assign({},item)
        this.newFoodDialogVisible = true
    }
    async _foodDeleteBtnClickEventHandler(item:Food){
        const res = await MessageBox.confirm('此操作将永久删除菜品, 是否继续?','提示')
        if(res === 'confirm'){
            const response = await Api.supplier_food_id_del(item.id)
            if(response.code === 200){
                Api.success('操作成功')
                this._refreshFoodClass()
            }
        }
    }
    _newBtnClickEventHandler(){
        
    }
    _newFoodExcelBtnClickEventHandler(){

    }
    pickFoodClickEventHandler(item:Food){
        const foodClass = this.foodClass
        const index = _.findIndex(item.classId,(o:string)=>{
            return o===foodClass.id
        })
        if(index!==-1){
            item.classId.splice(index, 1)
        }else{
            item.classId.push(foodClass.id)
        }
    }
    isCheck(item:Food){
        const foodClass = this.foodClass
        const index = _.findIndex(item.classId,(o:string)=>{
            return o===foodClass.id
        })
        return index !== -1
    }
    async _pickFoodConfirmBtnClickEventHandler(){
        const response =  await Api.supplier_food_batch_id(this.foodClass.id,this.foods)
        if(response.code === 200){
            Api.success('操作成功')
            this._refreshFoodClass()
            this.pickFoodDialogVisible = false
        }
    }
    _newFoodClassClickEventHandler(){
        const form:any = this.$refs['newFoodClassForm']
        form.validated().then(()=>{
            form.submit()
        })
    }
    _newSupplierClickEventHandler(){
        const form:any = this.$refs['newSupplierForm']
        form.validated().then(()=>{
            form.submit()
        })
    }
    _newSupplierDialogSuccessHandler(res:Response<any>){
        if(res.code===200){
            Api.success('操作成功')
            this._refreshFoodClass()
            this.newSupplierDialogVisible = false
        }
    }
    _newFoodClassDialogSuccessHandler(res:Response<any>){
        if(res.code===200){
            Api.success('操作成功')
            this._refreshFoodClass()
            this.newFoodClassDialogVisible = false
        }
    }
    async _sortFoodClassBtnClickEventHandler(item:FoodClass){
        this.foodClass = item
        const response: Response<Array<Supplier>>  = await Api.supplier()
        if(response.code === 200){
            const supplier:Supplier = _.find(response.data,(o:Supplier)=>{
                return o.id === this.supplier.id
            }) as Supplier
            const foodClass:FoodClass = _.find(supplier.foodClass,(o:FoodClass)=>{
                return o.name === '全部'
            }) as FoodClass
            const storeFoodConfigResponse = await Api.store_food_config(item.id)
            if(storeFoodConfigResponse.code === 200){
                const storeFoodConfig = storeFoodConfigResponse.data
                const foods:Array<Food> = []
                for(let config of storeFoodConfig){
                    const food = _.find(foodClass.foods,o => o.id === config.foodId) as Food
                    foods.push(food)
                }
                this.foods = foods
            }
        }
        this.sortFoodDialogVisible = true
    }
    async _sortFoodConfirmBtnClickEventHandler(){
        const response =  await Api.supplier_food_batch_id(this.foodClass.id,this.foods)
        if(response.code === 200){
            Api.success('操作成功')
            this._refreshFoodClass()
            this.sortFoodDialogVisible = false
        }
    }
    async _deleteFoodClassBtnClickEventHandler(item:FoodClass){
        const res = await MessageBox.confirm('此操作将永久删除分组, 是否继续?','提示')
        if(res === 'confirm'){
            const response = await Api.supplier_food_class_id_del(item.id)
            if(response.code === 200){
                Api.success('操作成功')
                this._refreshFoodClass()
            }
        }
    }
    _newDialogFailHandler(){
        Api.error('服务器异常')
    }
    _newSupplierBtnClickEventHandler(){
        this.newSupplierDialogVisible = true
    }
    @Watch('newFoodDialogVisible')
    _onNewFoodDialogVisibleChanged(){
        this._refreshFoodClass()
    }
    
}
