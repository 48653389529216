





import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TText extends Vue {
  @Prop({type:String,required:false}) private color!: string ;
  @Prop({type:String,default:'small'}) private size!: string ;
  @Prop({type:String,default:'center'}) private align!: string ;
}
