







import App from "@/api/Api";
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class Form extends Vue {
    // mounted(){
    //     console.log(this.$children)
    // }
    @Prop({type:String,required:false}) private action!: string;    // 提交URL地址
    @Prop({type:String,required:false}) private method!: string;    // HTTP 请求提交方式
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Prop({type:Function,required:false}) private success!: Function;    // HTTP 请求提交方式
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Prop({type:Function,required:false}) private fail!: Function;    // HTTP 请求提交方式
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    validated(){
        const promises: Array<Promise<any>> = []
        for(let i=0;i<this.$children.length;i++){
            const child: any = this.$children[i]
            if(child.validate instanceof Function){
                promises.push(child.validate())
            }
        }
        return new Promise((resolve,reject)=>{
            Promise.all(promises).then(res => {
                const params: any = {}
                for(let i=0;i<res.length;i++){
                    const keys = Object.keys(res[i])
                    for(let j =0;j<keys.length;j++){
                        const key = keys[j]
                        params[key]  = res[i][key]
                    }
                }
                resolve(params)
            }).catch((err)=>{
                reject(err)
            })
        })

    }
    clearValidated():void{
        console.log("clearValidated")
    }
    async submit(success: Function,fail: Function){
        let method = this.$props.method
        if(!method){
            method = 'GET'
        }
        method = method.toLocaleUpperCase()
        const url = this.$props.action
        const _success: Function = this.$props.success instanceof Function ? this.$props.success : success
        const _fail: Function = this.$props.fail instanceof Function ? this.$props.fail : fail
        
        let response: any = null
        const params: any = await this.validated()

        try{
            if(method==='POST'){
                response = await App.post(url,params)
            }else if(method === 'GET'){
                response = await App.get(url,params)
            }
            _success(response)
        }catch(e){
            _fail(response)
        }
    }
}
