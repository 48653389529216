




























































import Api, { Response, Project,Calendar,Day,Supplier,Food, FoodClass, ProjectAddress } from "@/api/Api";
import { MessageBox } from 'element-ui';
import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class ProjectVue extends Vue {
    project = [] as Array<Project>
    projectForm = {} as Project
    dialogVisible = false
    addressList = ''
    rules = {
        name:[{required: true, message: '请输入名称', trigger: 'blur'}]
    }
  beforeMount() {
      this._refreshTable()
  }
  async _refreshTable(){
     const res:Response<Array<Project>> = await Api.project()
     if(res.code===200){
         this.project = res.data
     }
  }
  _projectNewBtnClickEventHandler(){
    this.dialogVisible = true
    this.projectForm = {} as Project
  }
  _projectEditBtnClickEventHandler(item:Project){
    const project =  Object.assign({},item)

    let str = ''
    for(let i=0;i<item.addressList.length;i++){
        const address:ProjectAddress = item.addressList[i] as ProjectAddress
        str += `${address.address1}/${address.address2}/${address.address3};\r\n`
    }
    project.addressList = str
    this.projectForm = project
    this.dialogVisible = true
  }
    async _projectDeleteBtnClickEventHandler(item:Project){
        const result = await this.$confirm('此操作将永久删除该项目, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        if(result === 'confirm'){
           const res = await Api.project_delete(item.id)
            if(res.code===200){
                Api.success('操作成功')
                this._refreshTable()
                this.dialogVisible = false
            }
        }
    }
    async _confirmBtnClickEventHandler(){
        const form:any = this.$refs['projectForm']
        const flag = await form.validate()
        if(!flag){
            return
        }

        const update = async ()=>{
            const res = await Api.project_update(this.projectForm)
            if(res.code===200){
                Api.success('操作成功')
                this._refreshTable()
                this.dialogVisible = false
            }
        }

        const _new = async () =>{
            const res = await Api.project_new(this.projectForm)
            if(res.code===200){
                Api.success('操作成功')
                this._refreshTable()
                this.dialogVisible = false
            }
        }

        this.projectForm.id ? update() : _new()
        
    }
}
