
















































































































































import _ from "lodash";
import Api, { Response, Project,Calendar,Day,Supplier,Food, ProjectConfig, StoreOrder } from "@/api/Api";
import { Component, Vue,Watch } from "vue-property-decorator";
@Component
export default class Order extends Vue {
    filterParams = {status:[],projectId:null,keyword:null,dateRange:''}
    project = [] as Array<Project>
    order = [] as Array<StoreOrder>
    status = [{value:1,label:'待支付'},
               {value:2,label:'支付中'},
                            {value:3,label:'支付成功/待配送'},
                            {value:4,label:'待评价'},
                            {value:5,label:'已评价'},
                            {value:6,label:'取消'},
                            {value:7,label:'退款中'},
                            {value:8,label:'退款成功'},
                            {value:9,label:'退款失败'},
                         ] as Array<any>
    tableLoading = true
    beforeMount(){
        this._init()
    }
    async _init(){
        const response = await Api.project()
        this.project = response.data
        const orderResponse = await Api.order(null,null,null)
        this.tableLoading = false
        this.order = orderResponse.data
    }
    getStatusValue(status:number){
        return _.find(this.status,(o)=> o.value === status).label
    }
    // 订单下载处理
    _orderDownloadBtnClickEventHandler(){
        Api.info("正在下载中，请耐心等待")
        Api.download('/mt/authenticated/order/excel',this.filterParams)
    }
    async _refundBtnClickEventHandler(order:StoreOrder){
        const result = await this.$confirm('请确认实付退款?', '提示')
        if(result==='confirm'){
            const res = await Api.order_refund(order.id)
            if(res.code===200){
                Api.success('操作成功')
                this._onFilterParamsChanged(this.filterParams)
            }else{
                Api.error('服务器异常')
            }
        }
    }
    @Watch('filterParams',{immediate: true, deep: true})
    async _onFilterParamsChanged(newValue:any){
      this.tableLoading = true
      this.order = []
      const dateRange = newValue.dateRange
      const orderResponse = await Api.order(newValue.projectId,newValue.status,newValue.keyword,dateRange)
      this.tableLoading = false
      this.order = orderResponse.data
    }
}
