



























































































import moment from "moment";
import _ from "lodash";
import Api, { Response, Project,Calendar,Day,Supplier, FoodClass, DayOfWeek, ProjectConfig } from "@/api/Api";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
  },
})
export default class Home extends Vue {
  supplier = [] as Array<Supplier>
  foodClass = [] as Array<FoodClass>
  project = [] as Array<Project>;
  currentProject = {} as Project
  days = [] as Array<Day>;
  currentDay = {} as Day
  currentDate = '' 
  menu = [] as Array<FoodClass>
  checked = [] as Array<boolean>
  currentProjectConfig = {} as ProjectConfig
  arriveTime = [] as Array<string>
  closeTime = ''
  arriveTimeOptions = [
    '00:00','00:15','00:30','00:45',
    '01:00','01:15','01:30','01:45',
    '02:00','02:15','02:30','02:45',
    '03:00','03:15','03:30','03:45',
    '04:00','04:15','04:30','04:45',
    '05:00','05:15','05:30','05:45',
    '06:00','06:15','06:30','06:45',
    '07:00','07:15','07:30','07:45',
    '08:00','08:15','08:30','08:45',
    '09:00','09:15','09:30','09:45',
    '10:00','10:15','10:30','10:45',
    '11:00','11:15','11:30','11:45',
    '12:00','12:15','12:30','12:45',
    '13:00','13:15','13:30','13:45',
    '14:00','14:15','14:30','14:45',
    '15:00','15:15','15:30','15:45',
    '16:00','16:15','16:30','16:45',
    '17:00','17:15','17:30','17:45',
    '18:00','18:15','18:30','18:45',
    '19:00','19:15','19:30','19:45',
    '20:00','20:15','20:30','20:45',
    '21:00','21:15','21:30','21:45',
    '22:00','22:15','22:30','22:45',
    '23:00','23:15','23:30','23:45',] as Array<string>
  async beforeMount() {
    const response = await Api.project();
    this.project = response.data;
    if(response.data.length==0){
      Api.info('当前自动调整到新增项目')
      setTimeout(()=>{
        Api.route('/project')
      },1000)
      return
    }
    await this._menuItemClickEventHandler(response.data[0])
    const res = await Api.supplier()
    this.supplier = res.data
    this.foodClass = []
    for(let i=0;i<res.data.length;i++){
      const supplier = res.data[i]
      for(let j=0;j<supplier.foodClass.length;j++){
        const foodClass = supplier.foodClass[j]
        this.foodClass.push(foodClass)
      }
    }

    for(let i=0;i<this.foodClass.length;i++){
      this.checked[i] = this.isCheck(this.foodClass[i])
    }
    const configResponse = await Api.project_config(response.data[0].id,this.currentDay.id)
    this.currentProjectConfig = configResponse.data
    const d = new Date(configResponse.data.closeTime)
    this.closeTime = moment(d).format('HH:mm')
    this.arriveTime = configResponse.data.arriveTime
  }
  async _menuItemClickEventHandler(item:Project){
    this.foodClass = []
    this.menu = []
    this.checked = []
    const day = this.currentDay
    let dayOfWeek:DayOfWeek | null = null
    switch(day.id){
      case 0:
        dayOfWeek = DayOfWeek.周日
        break
      case 1:
        dayOfWeek = DayOfWeek.周一
        break
      case 2:
        dayOfWeek = DayOfWeek.周二
        break
      case 3:
        dayOfWeek = DayOfWeek.周三
        break
      case 4:
        dayOfWeek = DayOfWeek.周四
        break
      case 5:
        dayOfWeek = DayOfWeek.周五
        break
      case 6:
        dayOfWeek = DayOfWeek.周六
        break
    }
    const response:Response<Calendar> = await Api.calendar(item.id,dayOfWeek)
    this.days = response.data.days
    this.currentDay = response.data.days[0]
    this.menu = response.data.menu
    this.currentProject = item
    this.currentDate = response.data.days[0].date
    const res = await Api.supplier()
    this.supplier = res.data
    for(let i=0;i<res.data.length;i++){
      const supplier = res.data[i]
      for(let j=0;j<supplier.foodClass.length;j++){
        const foodClass = supplier.foodClass[j]
        this.foodClass.push(foodClass)
      }
    }

    for(let i=0;i<this.foodClass.length;i++){
      this.checked[i] = this.isCheck(this.foodClass[i])
    }
    const configResponse = await Api.project_config(item.id,this.currentDay.id)
    this.currentProjectConfig = configResponse.data
    const d = new Date(configResponse.data.closeTime)
    this.closeTime = moment(d).format('HH:mm')
    this.arriveTime = configResponse.data.arriveTime    
  }
  getSupplierNameById(supplierId:string):string{
    const supplier = _.find(this.supplier,o => {
      return o.id === supplierId
    }) as Supplier
    return supplier.name
  }
  _tabsClickEventHandler(item:any){
    const name = item.name
    const day:Day = _.find(this.days,(o:Day)=> {
      return o.date === name
    }) as Day
    this.currentDay = day
    this._tabClickEventHandler(day)
  }
  async _tabClickEventHandler(day:Day){
    this.foodClass = []
    this.menu = []
    this.supplier = []
    this.foodClass = []
    this.closeTime = ''
    this.arriveTime = []
    const project = this.currentProject
    let dayOfWeek:DayOfWeek = DayOfWeek.周日
    switch(day.id){
      case 0:
        dayOfWeek = DayOfWeek.周日
        break
      case 1:
        dayOfWeek = DayOfWeek.周一
        break
      case 2:
        dayOfWeek = DayOfWeek.周二
        break
      case 3:
        dayOfWeek = DayOfWeek.周三
        break
      case 4:
        dayOfWeek = DayOfWeek.周四
        break
      case 5:
        dayOfWeek = DayOfWeek.周五
        break
      case 6:
        dayOfWeek = DayOfWeek.周六
        break
    }
    const response:Response<Calendar> = await Api.calendar(project.id,dayOfWeek)
    this.days = response.data.days
    this.menu = response.data.menu

    const res = await Api.supplier()
    this.supplier = res.data
    for(let i=0;i<res.data.length;i++){
      const supplier = res.data[i]
      for(let j=0;j<supplier.foodClass.length;j++){
        const foodClass = supplier.foodClass[j]
        this.foodClass.push(foodClass)
      }
    }

    for(let i=0;i<this.foodClass.length;i++){
      this.checked[i] = this.isCheck(this.foodClass[i])
    }

    const configResponse = await Api.project_config(project.id,day.id)
    this.currentProjectConfig = configResponse.data
    const d = new Date(configResponse.data.closeTime)
    this.closeTime = moment(d).format('HH:mm')
    this.arriveTime = configResponse.data.arriveTime
  }
  _checkBoxClickEventHandler(e:MouseEvent){
    e.stopPropagation()
  }
  async _checkBoxChangeEventHandler(checked:boolean,item:FoodClass){
    const project = this.currentProject
    const day = this.currentDay
    if(checked){
      const response = await Api.menu({classId:item.id,projectId:project.id,day:day.id})
      if(response.code===200){
        Api.success('操作成功')
      }
    }else{
      const response = await Api.menu_del(item.id,project.id,day.id)
      if(response.code===200){
        Api.success('操作成功')
      }
    }

    let dayOfWeek:DayOfWeek = DayOfWeek.周日
    switch(day.id){
      case 0:
        dayOfWeek = DayOfWeek.周日
        break
      case 1:
        dayOfWeek = DayOfWeek.周一
        break
      case 2:
        dayOfWeek = DayOfWeek.周二
        break
      case 3:
        dayOfWeek = DayOfWeek.周三
        break
      case 4:
        dayOfWeek = DayOfWeek.周四
        break
      case 5:
        dayOfWeek = DayOfWeek.周五
        break
      case 6:
        dayOfWeek = DayOfWeek.周六
        break
    }
    const response:Response<Calendar> = await Api.calendar(project.id,dayOfWeek)
    this.days = response.data.days
    this.menu = response.data.menu
    const days = response.data.days
    this.currentDay = _.find(days,(o:Day)=> o.id === this.currentDay.id) as Day
    
  }
  isCheck(item:FoodClass):boolean{
    const index = _.findIndex(this.menu,(o:FoodClass)=> o.id === item.id)
    return index !== -1
  }
  async _closeTimeChangedEventHandler(closeTime:string){
    const arr = closeTime.split(':')
    const closeOffsetMinute = Number.parseInt(arr[0]) * 60 + Number.parseInt(arr[1])
    this.currentProjectConfig.closeOffsetMinute = closeOffsetMinute
    const response = await Api.project_config_update(this.currentProjectConfig)
    if(response.code === 200){
      Api.success('操作成功')
    }
  }
  async _arriveTimeChangeEventHandler(arriveTime:Array<string>){
    this.currentProjectConfig.arriveTime = arriveTime
    this.currentProjectConfig.arrive_offset_minute = []
    for(let i=0;i<arriveTime.length;i++){
      const arr = arriveTime[i].split(':')
      this.currentProjectConfig.arrive_offset_minute[i] = Number.parseInt(arr[0]) * 60 + Number.parseInt(arr[1])
    }
    const response = await Api.project_config_update(this.currentProjectConfig)
    if(response.code === 200){
      Api.success('操作成功')
    }
  }
}
