










import { Component, Prop, Vue, Watch  } from 'vue-property-decorator';
@Component
export default class Input extends Vue{
    beforeMount(){
        this.$data.inputValue = this.$props.value
        if(this.$props.helpWay === 'always'){
            this.showHelpText = true
        }
    }
    isError = false;
    isFocus = false;
    inputValue = '';
    showHelpText = false;
    haveFocus = false;
    errorText = ''
    @Prop({type:String,default:'small'}) private size!: string;
    @Prop({type:String,required:false}) private label!: string ;
    @Prop({type:String,required:false}) private type!: string ;
    @Prop({type:String,required:true}) private id!: string ;
    @Prop({type:String,required:false}) private value!: string ;
    @Prop({type:String,required:false}) private 'help-text'!: string ;
    @Prop({type:String,default:'normal'}) private 'help-way'!: string;  // always、normal
    @Prop({type:Boolean,default:false}) private 'help-icon'!: boolean;  
    @Prop({type:Boolean,default:false}) private 'help-required'!: boolean; 
    @Prop({type:Function,required:false}) private 'help-validator'!: Function;  // always、normal
    @Prop({type:Function,required:false}) private validator!: Function ;
    @Watch('helpRequired')
    _onHelpRequiredChanged(): void{
        this.validate()
    }
    _change(value: string | number){
        this.$emit('input',value)
    }
    _focus(){
        this.$data.isFocus = true
    }
    _blur(){
        this.$data.isFocus = false
        this.errorText = ''
        this.haveFocus = true
        if(this.$props.helpRequired && !this.inputValue){
            this.$data.isError = true
            this.showHelpText = true
        }else if(this.$props.helpRequired && this.$props.helpValidator){
            this.validate()
        }else{
            this.$data.isError = false
            if(this.$props.helpWay === 'normal'){
                this.showHelpText = false
            }
        }
    }
    validate():Promise<any>{
        const key = this.$props.id
        const value = this.$props.value
                
        return new Promise((resolve,reject)=>{
            if(!this.$props.helpRequired){
                // 非必须验证
                this.$data.isError = false
                if(this.$props.helpWay === 'normal'){
                    this.showHelpText = false
                }                
                resolve({[key]:value})
                return
            }

            const success = ()=>{
                console.log('success')
                this.$data.isError = false
                if(this.$props.helpWay === 'normal'){
                    this.showHelpText = false
                }
                resolve({[key]:value})
            }
            const fail = ()=>{
                console.log('fail')
                this.$data.isError = true
                reject()
            }
            if(this.$props.helpValidator){
                this.$props.helpValidator(success,fail)
            }

            if(this.$props.value){
                resolve({[key]:value})
            }else{
                this.$data.isError = true
                this.showHelpText = true
                reject('Input 组件 id:'+key+"的 value 属性不能为空")
            }
        })
    }
    error(errorText: string): void{
        this.$data.isError = true
        this.showHelpText = false
        if(this.errorText === errorText){
            // 通过定时器实现一种闪烁效果
            this.errorText = ''
            setTimeout(()=>{
                this.errorText = errorText
            },100)
        }else{
            this.errorText = errorText
        }
    }
}
