







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Button extends Vue {
    name = 'ELTButton'
    beforeMount(){
        console.log('beforeMount')
    }
    loading = false;
    @Prop({type:String,default:'small'}) private size!: string;
    @Prop({type:String,default:'primary'}) private type!: string; // primary、submit
    _click(){
        if(this.type === 'submit'){
            this._doSubmit()
        }else{
            this.$emit('click')
        }
    }
    _doSubmit(){
        const parent: any = this.$parent
        if(parent.submit instanceof Function){
            try {
                this.loading = true
                const p: Promise<Record<string, any>> = parent.submit()     
                p.finally(()=>{
                    this.loading = false
                })
            } catch (error) {
                 this.loading = false
            }
        }
    }
}
