


















import _ from "lodash";
import Api, { Response, Project,Calendar,Day,Supplier,Food, FoodClass } from "@/api/Api";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
    components:{
    }
})
export default class BusinessLicense extends Vue {
    headers = {} as any
    form = {picUrl:''} as Food
    async beforeMount() {
        this.headers['WEBADMINUSERSESSIONID'] = Api.getHeader('WEBADMINUSERSESSIONID')
        this.form.picUrl = await (await Api.project_license_get()).data
    }
    pictureUploadSuccess(res:Response<{url:string}>){
        if(res.code===200){
            this.form.picUrl = res.data.url
        }
    }
    async _confirmBtnClickEventHandler(){
       const response = await Api.project_license(this.form.picUrl as string)
       if(response.code===200){
           Api.success('操作成功')
       }else{
           Api.error("操作失败")
       }
    }
}
