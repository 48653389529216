































































import Form from "@/components/Form.vue";
import Input from "@/components/Input.vue";
import Button from "@/components/Button.vue";
import TText from "@/components/Text.vue";
import App,{Response,WebAdminUser} from "@/api/Api";

import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    "ts-input": Input,
    "ts-Button": Button,
    "ts-text": TText,
    "ts-form": Form,
  },
  beforeMount() {
    console.log("login");
  },
  data() {
    return {
      select: "user",
      token: "",
      loginName: "",
      password: "",
    };
  },
  methods: {
    _select(select: string) {
      this.$data.select = select;
    },
    successHandler(response: Response<WebAdminUser>) {
      try {
        if(response.code === 200){
            // 登录成功
            App.putHeader("WEBADMINUSERSESSIONID",response.data.WEBADMINUSERSESSIONID)
            App.route("/home")
            return
        }

        if(response.code === 401.2){
            const input: any = this.$refs.passwordInput
            App.error(response.message)
            return
        }
      } catch (error) {
        // App.catchHandler(response);
      }
    },
    failHandler(err) {
      console.log('failHandler')
    //   App.catchHandler(err);
    },
  },
})
export default class Login extends Vue {}
