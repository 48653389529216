



















import App from "@/api/Api";
import { Component, Prop, Vue, Watch  } from 'vue-property-decorator';
@Component
export default class Input extends Vue{
    imageUrl = ''
    base64 : string | ArrayBuffer | null = ''
    isError = false
    file : any = null
    @Prop({type:String,required:true}) private id!: string ;
    @Prop({type:String,required:false}) private value!: string ;
    @Prop({type:String,required:true}) private action!: string;
    @Prop({type:Number,default:1024*1024*4}) private fileSize!: string;
    @Prop({type:String,default:'image/jpeg'}) private accept!: string;
    @Prop({type:Boolean,default:true}) private 'auto-upload'!: boolean;
    @Prop({type:Boolean,default:false}) private 'help-required'!: boolean; 
    @Prop({type:String,default:'只能上传jpg文件，且不超过4MB'}) private 'help-text'!: string ;
    @Watch('value')
    watchValue(newValue:string,oldValue:string){
        this.$data.imageUrl = newValue
    }
    handleAvatarSuccess(res: any, file: any){
        const callback = (imgUrl:string)=>{
            this.imageUrl = imgUrl
            this.$emit('input',imgUrl)
        }
        this.$emit('success',res,callback)
    }   
    beforeAvatarUpload(file:any){
        const isLimit = file.size < this.$props.fileSize;
        if(!isLimit){
            this.isError = true
        }else{
            this.isError = false
        }

        if(!this.imageUrl){
            this.isError = true
        }else{
            this.isError = false
        }
        return isLimit
    }
    handleChange(){
        this.base64 = null
        const upload:any = this.$refs.upload
        const children = upload.$el.children[0].children
        let input = null
        for(let child of children){
            if(child.type === 'file'){
                input = child
                break;
            }
        }
        if(input){
            const _this = this
            const file = input.files[0]
            this.file = file
            const reader = new FileReader()
            reader.onload = function(e){
                const newUrl = this.result
                // const img:any = _this.$refs.img
                _this.$data.imageUrl = newUrl
                // img.src = newUrl
                _this.base64 = newUrl
            }
            reader.readAsDataURL(file)
        }
    }
    validate():Promise<any>{
        const key = this.$props.id
        if(this.base64){
            const params = {
                name:this.file.name,
                size:this.file.size,
                type:this.file.type,
                base64:this.base64
            }
            return new Promise((resolve,reject)=>{
                const p = App.post(this.$props.action,params)
                p.then(res=>{
                    const callback = (imgUrl:string)=>{
                        this.imageUrl = imgUrl
                        this.$emit('input',imgUrl)
                        resolve({[key]:imgUrl})
                    }
                    this.$emit('success',res,callback)
                })

                p.catch(err=>{
                    reject()
                })
            })
        }

        const value = this.$props.value
            return new Promise((resolve,reject)=>{
                if(!this.$props.helpRequired){
                    // 非必须验证
                    this.isError = false
                    resolve({[key]:value})
                }else{
                    // 必须验证
                    if(value){
                        this.isError = false
                        resolve({[key]:value})
                    }else{
                        this.isError = true
                        reject()
                    }
                }
                return
            })
    }
}
